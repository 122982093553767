import { datadogRum } from '@datadog/browser-rum';
import '@helcim/blizzard-core/dist/blizzard.min.css';
import { createApp } from 'vue';
import App from './App.vue';

if (!/\.test$/.test(window.location.hostname)) {
    datadogRum.init({
        applicationId: '2d47bb9b-9d59-432c-b817-18e1ad8dd242',
        clientToken: 'pubfac58a5e2bd099debb583a85ea41bd30',
        site: 'us5.datadoghq.com',
        proxy: 'https://apm-proxy.helcim.com/apm/in',
        service: 'ecommerce',
        env: 'production',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [/https:\/\/.*\.helcim\.com/, /https:\/\/.*\.myhelcim\.com/, /https:\/\/.*\.helcim\.app/],
    });
    const username = sessionStorage.getItem('username');
    if (username) {
        datadogRum.setUser({
            name: username,
        });
    }
}

createApp(App).mount('#app');

