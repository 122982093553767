<template>
    <div class="flex-center" style="width: 100vw; height: 100vh">
        <div class="d-flex flex-column align-items-center gap-24">
            <img src="/error.svg" width="360" height="360" alt="Error 404">
            <div class="d-flex flex-column align-items-center">
                <span class="hb24 text-midnight-1000">Unable to load store</span>
                <span class="tr16 text-midnight-700">No store found for this subdomain!</span>
            </div>
        </div>
    </div>
</template>
